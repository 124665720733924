import React, { useRef } from 'react';
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import whatsapp from '../../assests/images/whatsapp.png'
import instagram from '../../assests/images/instagram.png'
import facebook from '../../assests/images/facebook.png'
import youtube from '../../assests/images/youtube.png'
import telegram from '../../assests/images/telegram.png'
import Pinterst from '../../assests/images/pinterest.png'
import linkedin from '../../assests/images/linkedin.png'
import twitter from '../../assests/images/twitter.png'
import { Link } from 'react-router-dom';
// import EbonyTree from "../../assests/all-images/ebony-tree-pdf/Ebony-Karungali.pdf";
const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="footer-main">
      <footer className="footer text-white">
        <div className="container-fluid">
          <div className="row justify-content-evenly">
            <nav className="col-md-4 col-lg-2 mb-3 text-md-start text-start">
              <ul className="list-unstyled d-flex footer-menu flex-column flex-lg-column  gap-2">
                <h4>Menu</h4>
                <li>
                  <Link to="/" className="text-white text-decoration-none " onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-white text-decoration-none" onClick={scrollToTop}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="text-white text-decoration-none" onClick={scrollToTop}>
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-white text-decoration-none" onClick={scrollToTop}>
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/ebonytree" className="text-decoration-none" style={{fontWeight:"900",color:"#cddc39"}} onClick={scrollToTop}>
                    Ebony Tree(Karungali Wood Benefits)
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-white text-decoration-none" onClick={scrollToTop}>
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/refundpolicy" className="text-white text-decoration-none" onClick={scrollToTop}>
                    Refund & Policy
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="col-md-4 col-lg-2 mb-4 footer-address">
              <h4>Contact Us</h4>
              <p className='m-0'>No.5a,159 Kundrathur Main Rd, Kovur</p>
              <p className='m-0'>Chennai, Tamil Nadu</p>
              <p className='m-0'>Pincode - 600128</p>
              <p className='m-0'>Email: info@srisakthiwoodworks.com</p>
              <p className='m-0'>Phone: 73972 59981, 99406 13493</p>
            </div>
            <div className="col-md-3 text-start mb-4">
              <h4>Follow Us</h4>
              <div className="d-flex justify-content-start align-items-center flex-wrap gap-2 w-100">
                <a href="https://www.facebook.com/profile.php?id=61569566267809" target='_black' className="text-white me-3" aria-label="Facebook">
                  <img className="social-media" src={facebook} alt="Facebook" />
                </a>
                <a href="https://wa.me/917397259981" target='_black' className="text-white me-3" aria-label="Whatsapp">
                  <img className="social-media" src={whatsapp} alt="Whatsapp" />
                </a>
                <a href="https://www.instagram.com/srisakthiwoodworks/" target='_black' className="text-white me-3" aria-label="Instagram">
                  <img className="social-media" src={instagram} alt="Instagram" />
                </a>
                <a href="https://www.youtube.com/@SriSakthiWoodWorks" target='_black' className="text-white me-3" aria-label="YouTube">
                  <img className="social-media" src={youtube} alt="YouTube" />
                </a>
                {/* <a href="#telegram" target='_black' className="text-white me-3" aria-label="Telegram">
                  <img className="social-media" src={telegram} alt="Telegram" />
                </a> */}
                <a href="https://in.pinterest.com/srisakthiwoodworld/" target='_black' className="text-white me-3" aria-label="Telegram">
                  <img className="social-media" src={Pinterst} alt="Telegram" />
                </a>
                <a href="https://www.linkedin.com/in/sri-sakthi-wood-works-393a1333b/" target='_black' className="text-white me-3" aria-label="LinkedIn">
                  <img className="social-media" src={linkedin} alt="LinkedIn" />
                </a>
                <a href="https://x.com/SrisakthiWood" target='_black' className="text-white" aria-label="Twitter">
                  <img className="social-media" src={twitter} alt="Twitter" />
                </a>
              </div>
            </div>

            <div className="col-md-3 p-0 d-flex align-items-stretch">
              <div className="map-wrapper">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7774.849327473638!2d80.1185408153432!3d13.008607172678822!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52603fa576ca09%3A0xc55b05964564e5a3!2sSRI%20SAKTHI%20WOOD%20WORKS!5e0!3m2!1sen!2sin!4v1731581180033!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 
              </div>
            </div>

          </div>
          <div className="footer-bottom mt-4 text-center">
            <p>&copy; 2024 Fayn Global. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
