import React, { useState } from 'react';
import './about.css';
import pic6 from "../../assests/images/pic6.png"
import wood1 from "../../assests/all-images/about-us/how can/image-1.jpg"
import wood2 from "../../assests/all-images/about-us/how can/image-2.png"
import wood3 from "../../assests/all-images/about-us/how can/image-3.jpg"
import wood4 from "../../assests/images/wood4.png"
import wood5 from "../../assests/images/wood5.jpeg"
import oakImage from "../../assests/images/oakImage.png"
import pineImage from "../../assests/images/pineImage.png"
import teakImage from "../../assests/images/teakImage.png"
import mapleImage from "../../assests/images/mapleImage.png"
import footer1 from "../../assests/images/footer5.jpg"
import { Tab, Nav } from 'react-bootstrap';
import { Container, Row, Col, Button } from 'react-bootstrap';
import footer5 from "../../assests/images/footer5.jpg";
import ProfilePicture from "../../assests/all-images/profile/profile-picture.jpeg";
import banner1 from "../../assests/all-images/about-us/image-3.png";

import video1 from "../../assests/wood-image/about us/videos/video-1.mp4";
import video2 from "../../assests/wood-image/about us/videos/video-2.mp4";
import video3 from "../../assests/wood-image/about us/videos/video-3.mp4";
import video4 from "../../assests/wood-image/about us/videos/video-4.mp4";
import video5 from "../../assests/wood-image/about us/videos/video-5.mp4";
import video6 from "../../assests/wood-image/about us/videos/video-6.mp4";

const About = () => {

  const projects = [
    {
      title: "Oak Lumber",
      category: "Hardwood",
      image: video1,  // Replace with the actual image variable
      detailLink: "oak-lumber-detail"
    },
    {
      title: "Pine Planks",
      category: "Softwood",
      image: video2,  // Replace with the actual image variable
      detailLink: "pine-planks-detail"
    },
    {
      title: "Teak Wood",
      category: "Exotic Hardwood",
      image: video3,  // Replace with the actual image variable
      detailLink: "teak-wood-detail"
    },
    {
      title: "Cedar Boards",
      category: "Softwood",
      image: video4,
      detailLink: "cedar-boards-detail"
    },
    {
      title: "Maple Veneer",
      category: "Hardwood",
      image: video5,  // Replace with the actual image variable
      detailLink: "maple-veneer-detail"
    },
    {
      title: "Maple Veneer",
      category: "Hardwood",
      image: video6,  // Replace with the actual image variable
      detailLink: "maple-veneer-detail"
    }
    // {
    //   title: "Maple Veneer",
    //   category: "Hardwood",
    //   image: video6,  // Replace with the actual image variable
    //   detailLink: "maple-veneer-detail"
    // }
  ];

  return (
    <>
      <div className='container mt-0' style={{ padding: '120px 10px' }}>
        <Container className='p-0 m-0'>
          <Row className="hero-section">
            <Col className="text-center text-white" md={12}>
              <div className="content"> {/* New wrapper for content */}
                <h1 className="hero-heading">Welcome to <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong></h1>
                <p className="hero-subheading">
                  Where craftsmanship meets legacy, blending traditional techniques with modern innovations.
                </p>
                <Button
                  variant="light"
                  size="lg"
                  href="/contact"
                  style={{
                    zIndex: 3, // Ensure the button is above the content
                    pointerEvents: 'auto',
                  }}
                >
                  Get Started
                </Button>
              </div>
            </Col>
          </Row>


          <Row className="mission-statement">
            <Col md={12}>
              <h2 className="section-heading">Our Passion and Legacy</h2>
              <p>
                With over <span style={{ fontWeight: "700" }}>27 Years</span> of experience, every piece we create at Sri Sakthi Wood Works tells a story, seamlessly blending tradition and innovation in custom furniture, restorations, and décor.
              </p>
            </Col>
          </Row>

          <Row className="values-section">
            <Col md={12}>
              <h2 className="section-heading">Why Choose Us?</h2>
              <Row>
                <Col md={4}>
                  <div className="feature-box feature-box-1">
                    <h3>Expert Craftsmanship</h3>
                    <p> Crafted with love and precision, each piece showcases the artistry and quality of our skilled team</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="feature-box feature-box-2">
                    <h3>Customized Solutions</h3>
                    <p>We turn your ideas into reality, crafting custom pieces that reflect your unique vision.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="feature-box feature-box-3">
                    <h3>Challenging Projects</h3>
                    <p>We take on the toughest challenges, using our skills and creativity to deliver exceptional results</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="feature-box feature-box-4">
                    <h3>Sustainable Practices</h3>
                    <p>We prioritize sustainability, crafting beautiful pieces from eco-friendly, high-quality wood.
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="feature-box feature-box-5">
                    <h3>End-to-End Service</h3>
                    <p> Our goal is to ensure a smooth experience, providing support from the first consultation to final installation</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="feature-box feature-box-6">
                    <h3>Innovative Designs for Every Vision</h3>
                    <p>
                      We bring your ideas to life through innovative design, blending modern style with classic charm.
                    </p>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
          {/* <Row className="vision-mission py-5">
          <Col md={12} className="text-center">
            <h2 className="section-heading mb-4">Our Vision & Mission</h2>
          </Col>

          <Col md={6} className="d-flex justify-content-center">
            <div className="card shadow-sm border-0 p-4 mb-4 mb-md-0 w-100">
              <h3 className="card-title text-center mb-3">Our Vision</h3>
              <p className="card-text">
                To be the leading provider of exceptional woodworks, blending traditional craftsmanship with innovative
                design, while honoring our three generations of heritage. We strive to inspire and enrich lives through
                sustainable and timeless wood products that celebrate the beauty of nature.
              </p>
            </div>
          </Col>

          <Col md={6} className="d-flex justify-content-center">
            <div className="card shadow-sm border-0 p-4 w-100">
              <h3 className="card-title text-center mb-3">Our Mission</h3>
              <p className="card-text">
                Our mission is to create high-quality, handcrafted woodworks that reflect our commitment to craftsmanship,
                sustainability, and customer satisfaction. We aim to preserve our family legacy by using responsibly sourced
                materials and traditional techniques, ensuring that every piece we create is not just a product, but a work of
                art that enhances the spaces and lives of our customers.
              </p>
            </div>
          </Col>

          <Col md={12} className="mt-4">
            <div className="text-center">
              <p className="lead">
                At <strong style={{fontWeight:'800'}}>Sri Sakthi Wood Works </strong>, we craft 100% Vastu-compliant woodworks that prioritize quality and bring positivity,
                good fortune, and divine blessings into your home, allowing you to embrace our rich heritage with every piece.
              </p>
              <p className="lead">
                By merging traditional techniques with sustainable practices, we ensure that our woodworks are not just
                functional, but also serve as beautiful reminders of our rich heritage. Experience the harmony of craftsmanship
                and spirituality with every item, inviting a sense of peace and prosperity into your life.
              </p>
            </div>
          </Col>
        </Row> */}
          <Row className="vision-mission py-5">
            <Col md={12} className="text-center">
              <h2 className="section-heading mb-4">Our Vision & Mission</h2>
            </Col>

            <Col md={6} className="d-flex justify-content-center">
              <div className="card shadow-lg border-0 p-4 mb-4 mb-md-0 w-100">
                <h3 className="card-title text-center mb-3">Our Vision</h3>
                <p className="card-text">Our vision is to lead in woodwork by combining traditional craftsmanship with innovative design, celebrating nature’s beauty and our heritage.
                </p>
              </div>
            </Col>

            <Col md={6} className="d-flex justify-content-center">
              <div className="card shadow-lg border-0 p-4 w-100">
                <h3 className="card-title text-center mb-3">Our Mission</h3>
                <p className="card-text">Our mission is to create sustainable, high-quality woodworks that embody tradition, craftsmanship, and customer satisfaction.
                </p>
              </div>
            </Col>

          </Row>
          {/* <Col md={12} className="mt-4">
            <div className="text-start">
              <p className="lead highlight-text">
                At <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong>, we craft 100% Vastu-compliant woodworks that prioritize quality and bring positivity, good fortune, and divine blessings into your home, allowing you to embrace our rich heritage with every piece.
              </p>
              <p className="lead highlight-text">
                By merging traditional techniques with sustainable practices, we ensure that our woodworks are not just functional, but also serve as beautiful reminders of our rich heritage. Experience the harmony of craftsmanship and spirituality with every item, inviting a sense of peace and prosperity into your life.
              </p>
            </div>
          </Col>
          <Row className="commitment-quality py-1">
            <Col md={12}>
              <h2 className="section-heading">Our Commitment to Quality</h2>
              <p>
                At <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong>, quality is not just a promise; it's the foundation of our craftsmanship. We meticulously
                select the finest materials, ensuring that every project reflects our high standards. Our skilled artisans combine
                traditional techniques with modern technology, resulting in products that are not only beautiful but also durable
                and functional. From intricate carvings to robust furniture, our work is designed to stand the test of time.
              </p>
            </Col>
          </Row>
          <Row className="trust-building py-1">
            <Col md={12}>
              <h2 className="section-heading">Building Trust Through Excellence</h2>
              <p>
                Trust is earned through consistency and transparency. At <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong>, we build lasting relationships
                with our clients by delivering on our promises. Our attentive customer service ensures that you feel valued at
                every step of the process. We listen to your needs, provide expert guidance, and maintain open communication to
                ensure your complete satisfaction. Your dream project is our top priority, and we strive to exceed your
                expectations at every turn.
              </p>
            </Col>
          </Row>
          <Row className="trust-building py-1">
            <Col md={12}>
              <h2 className="section-heading">Innovative Designs for Every Vision</h2>
              <p>
                Innovation is at the heart of what we do. We are not just woodworkers; we are visionaries who embrace new
                ideas and techniques. Our design team is dedicated to pushing boundaries and exploring creative possibilities,
                allowing us to offer unique solutions that set your project apart. Whether you seek contemporary elegance or
                classic charm, our innovative designs will bring your ideas to life in ways you never imagined.
              </p>
            </Col>
          </Row>
          <Row className="trust-building py-1">
            <Col md={12}>
              <h2 className="section-heading">Explore Our Expertise</h2>
              <p>
                From bespoke furniture and custom cabinetry to stunning architectural features, <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong> offers a
                wide range of services tailored to your needs. Each project we undertake is a collaborative journey, where your
                vision meets our expertise. Browse our portfolio to see how we have transformed spaces and created timeless
                pieces that reflect our clients' individual styles.
              </p>
            </Col>
          </Row> */}
          <p className='px-3 mt-4'> <span style={{ fontWeight: "800", color: "#9A6D78" }}>At Sri Sakthi Wood Works,</span>  we create Vastu-compliant woodworks that combine quality, positivity, and good fortune, reflecting our rich heritage.</p>
          <div className='py-2 px-3 mb-5'>
            <h3 style={{ color: '#9A6D78' }}>Core Values:</h3>
            1. Our Commitment to Quality <br />
            2. Building Trust Through Excellence<br />
            3. Innovative Designs for Every Vision<br />
            4. Explore Our Expertise<br />

          </div>
          {/* <Row className="trust-building py-1">
            <Col md={12}>
              <h2 className="section-heading">Join Us in Crafting Your Dream</h2>
              <p>
                Discover the difference that quality, trust, and innovation can make in your woodworking projects. At Sakthi
                Wood Works, we are ready to partner with you to turn your ideas into breathtaking realities. Contact us today
                for a consultation, and let’s begin the journey of crafting something truly extraordinary together..
              </p>
            </Col>
          </Row> */}
          <Row
            className="contact-section py-5"
            id="get-started"
            style={{
              backgroundImage: `url(${banner1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              color: "white",
            }}
          >
            <Col md={12} className="text-center text-overlay">
              <h2 className="section-head">Get Started on Your Dream Project</h2>
              <p className="lead">
                Are you ready to create something amazing? <br /><span style={{ fontWeight: "800" }}>At Sri Sakthi Wood Works,</span> we bring your dream project to life with artistry and skill.
              </p>
              {/* <p>Contact us today for a consultation!</p>
              <p>Let’s Build Something Extraordinary Together</p> */}
              <Button variant="success" size="lg" href="/contact">
                Contact Us
              </Button>
            </Col>
          </Row>
        </Container>
        <div className='row justify-content-center'>
          <div className="col-lg-10 col-12 text-center mx-auto mb-5">
            {/* <small className="small-title">Services <strong className="text-warning ">03/05</strong></small>
            <h2 className='fs-2 fw-bolder'>How can we help you?</h2> */}
          </div>
          <Tab.Container defaultActiveKey="nav-woods">
            <div className="row">
              <div className=" col-12 col-lg-6 mb-2">
                <Nav variant="tabs" className="flex-column align-items-baseline gap-3">
                  <Nav.Item>
                    <Nav.Link eventKey="nav-woods">
                      <h3>Types of Wood</h3>
                      <span>Discover the diverse world of wood species.</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="nav-doors">
                      <h3>Door Styles</h3>
                      <span>Explore various door designs and materials.</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="nav-maintenance">
                      <h3>Wood Care and Maintenance</h3>
                      <span>Learn how to preserve and maintain wood.</span>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="nav-sustainability">
                      <h3>Sustainable Wood Practices</h3>
                      <span>Understand the importance of sustainable sourcing.</span>
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="nav-finishes">
                      <h3>Original Karungali Wood(Ebony Tree)</h3>
                      <span>Experience the purity of real Karungali wood from our collection.</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className=" col-12 col-lg-6">
                <Tab.Content>
                  <Tab.Pane eventKey="nav-woods">
                    <img src={wood1} alt="Types of Wood" className="img-fluid" style={{ width: '250px' }} />
                    <h5 className="mt-4 mb-2 fs-2 fw-bolder">Introduction to Types of Wood</h5>
                    <p>Wood is classified into two main categories: hardwood and softwood. Each type has unique properties that make it suitable for different applications.</p>
                    <p>Understanding these differences is essential for making informed decisions in woodworking and construction.</p>
                    <ul>
                      <li><strong>Hardwoods:</strong> Comes from deciduous trees. Examples include oak, maple, and cherry. Known for durability and grain patterns.</li>
                      <li><strong>Softwoods:</strong> Comes from coniferous trees. Examples include pine, cedar, and spruce. Often more affordable and easier to work with.</li>
                      <li><strong>Exotic Woods:</strong> Rare species like mahogany and teak, valued for their beauty and unique characteristics.</li>
                      <li><strong>Engineered Woods:</strong> Composite materials like plywood and MDF, which offer versatility and stability for various projects.</li>
                    </ul>
                  </Tab.Pane>

                  <Tab.Pane eventKey="nav-doors">
                    <img src={wood2} alt="Door Styles" className="img-fluid" style={{ width: '250px' }} />
                    <h5 className="mt-4 mb-2 fs-2 fw-bolder">Exploring Door Styles</h5>
                    <p>Doors are not just functional; they can enhance the aesthetic of your space. Here are popular styles:</p>
                    <p>Choosing the right door can significantly influence the atmosphere of a room, contributing to both functionality and design.</p>
                    <ul>
                      <li><strong>Panel Doors:</strong> Traditional design with raised or recessed panels.</li>
                      <li><strong>French Doors:</strong> Double doors with glass panes, perfect for connecting indoor and outdoor spaces.</li>
                      <li><strong>Sliding Doors:</strong> Space-saving doors that glide open, often used for patios.</li>
                      <li><strong>Barn Doors:</strong> Rustic charm with a sliding mechanism, ideal for contemporary and farmhouse styles.</li>
                      <li><strong>Bi-Fold Doors:</strong> Folding doors that open up space, great for large openings.</li>
                    </ul>
                  </Tab.Pane>

                  <Tab.Pane eventKey="nav-maintenance">
                    <img src={wood3} alt="Wood Care" className="img-fluid" style={{ width: '250px' }} />
                    <h5 className="mt-4 mb-2 fs-2 fw-bolder">Wood Care and Maintenance</h5>
                    <p>Maintaining wood properly ensures longevity and beauty. Here are some essential tips:</p>
                    <p>Regular care can prevent costly repairs and keep your wood looking its best for years to come.</p>
                    <ul>
                      <li><strong>Regular Cleaning:</strong> Dust surfaces with a soft cloth to prevent dirt buildup.</li>
                      <li><strong>Protect from Moisture:</strong> Use coasters and mats to avoid water damage and stains.</li>
                      <li><strong>Polishing:</strong> Apply furniture polish or wax periodically to enhance shine and protect the finish.</li>
                      <li><strong>Repairing Scratches:</strong> Use wood filler or touch-up markers for minor imperfections.</li>
                      <li><strong>Seasonal Care:</strong> Adjust humidity levels in your home to prevent wood from warping or cracking.</li>
                    </ul>
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="nav-sustainability">
                    <img src={wood4} alt="Sustainable Wood Practices" className="img-fluid" />
                    <h5 className="mt-4 mb-2 fs-2 fw-bolder">Sustainable Wood Practices</h5>
                    <p>Sustainable sourcing of wood helps protect forests and biodiversity. Here’s how to choose sustainable wood:</p>
                    <p>By selecting sustainably sourced wood, you contribute to the health of our planet and support responsible forestry practices.</p>
                    <ul>
                      <li><strong>Look for Certifications:</strong> Choose wood with FSC (Forest Stewardship Council) or SFI (Sustainable Forestry Initiative) certifications.</li>
                      <li><strong>Consider Reclaimed Wood:</strong> Using salvaged wood reduces the demand for new lumber and adds character.</li>
                      <li><strong>Support Local Suppliers:</strong> Buying locally sourced wood minimizes transportation impact and supports local economies.</li>
                      <li><strong>Educate Yourself:</strong> Stay informed about wood sourcing and its impact on the environment.</li>
                      <li><strong>Choose Alternative Materials:</strong> Explore bamboo or other sustainable materials as eco-friendly options.</li>
                    </ul>
                  </Tab.Pane> */}

                  <Tab.Pane eventKey="nav-finishes">
                    <img src={wood5} alt="Wood Finishing Techniques" className="img-fluid" style={{ width: '250px' }} />
                    <h5 className="mt-4 mb-2 fs-2 fw-bolder">Karungali Wood (Blackwood)</h5>
                    <p>Spiritual Significance :</p>
                    <p>Often used in temples and rituals for its sacred properties.
                      Believed to attract positive energy and ward off negativity.
                      Durability</p>
                    <ul>
                      <li>Extremely strong and resistant to wear, making it ideal for furniture and sculptures.
                        Natural Healing Properties</li>
                      <li>Known for its calming effect, helping to reduce stress and promote mental clarity.
                        Aromatic Qualities</li>
                      <li>Emits a subtle, natural fragrance that purifies the surrounding environment.
                        Symbol of Strength</li>
                      <li>Represents resilience and stability, making it a popular choice for traditional religious items.
                        Eco-Friendly</li>
                      <li>Sourced sustainably, it is a natural and biodegradable material.
                        Aesthetic Appeal</li>
                    </ul>
                  </Tab.Pane>
                </Tab.Content>

              </div>
            </div>
          </Tab.Container>



          <div className="row ">
            <div className="col-lg-10 col-12 text-center mx-auto mb-5">
              <small className="small-title">
                {/* Projects <strong className="text-warning">04/05</strong> */}
              </small>
              <h2>Brands we've crafted so far</h2>
            </div>

            {projects.map((project, index) => (
              // <div className={`col-lg-${index === 3 ? 8 : 4} col-12`} key={project.title}>
              <div className={`col-lg-4 col-12`} key={project.title}>
                <div className={`projects-thumb ${index === 3 ? 'projects-thumb-large' : 'projects-thumb-small'}`}>
                  {/* <img src={project.image} alt={project.title} className="img-fluid projects-image" width={500} height={367} /> */}
                  <video controls className="img-fluid projects-image" >
                    <source src={project.image} type="video/mp4" />
                  </video>
                  <div className="projects-info">
                    <div className="projects-title-wrap">
                      <small className="projects-small-title">{project.category}</small>
                      <h2 className="projects-title">{project.title}</h2>
                    </div>
                    {/* <div className="projects-btn-wrap mt-4">
                      <span className="custom-btn">
                        <i className="bi-arrow-right"></i>
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row mb-5">
            <div className="col-lg-6 col-12 mb-5 mb-lg-0">
              <div className="about-image-wrap h-100">
                <img src={ProfilePicture} className="img-fluid about-image" alt="Wood Architecture" />
                {/* <div className="about-image-info">
                  <h4 className="text-white">Emily Johnson, Architect</h4>
                  <p className="text-white mb-0">Explore the beauty of wood in architecture. Thank you for visiting our showcase.</p>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-12 d-flex flex-column">
              <div className="about-thumb bg-white shadow-lg">
                <div className="about-info">
                  <small className="small-title">About </small>
                  {/* <strong className="text-warning">02/05</strong> */}
                  <h5 className="mb-3 fs-3"> Mr. A. Vinoth Kumar</h5>
                  {/* <h5 className="mb-3">Crafting sustainable spaces since 2010</h5> */}
                  {/* <p>Our firm specializes in innovative architectural solutions using wood and custom doors. This template serves as a showcase for our work. Redistribution of this content is not permitted.</p> */}
                  {/* <p>Support our mission by visiting our <a href="https://www.example.com/contact">contact page</a>. Thank you.</p> */}
                  <p>Proudly following his father’s footsteps, coming from a family known for hard work and dedication. With a Master’s degree in Architecture, Has been trained in both traditional and modern techniques, enabling him to create unique and high-quality designs.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12 mb-2">
                  <div className="about-thumb d-flex flex-column justify-content-center mb-lg-0 h-100" style={{ backgroundColor: "rgb(154 109 120 / 90%)" }}>
                    <div className="about-info">
                      <h5 className="text-white mb-4"> Our Woodworking Expertise <br /> Transforming Visions into Reality</h5>
                      <a className="custom-btn custom-bg-primary bg-white text-dark" href="https://wa.me/917397259981">Join us</a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12 mb-2">
                  <div className="about-thumb d-flex flex-column justify-content-center mb-lg-0 h-100" style={{ backgroundColor: "#03A9F4" }}>
                    <div className="about-info">
                      <h5 className="text-white mb-4">How did you hear about us?</h5>
                      <p className="text-white mb-0">Your feedback helps us grow and innovate.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <section />
    </>
  );
};

export default About;

