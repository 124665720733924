import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { motion } from "framer-motion";
// import Image1 from "../../assests/all-images/Ebony-tree/image-1.png";
// import Image2 from "../../assests/all-images/Ebony-tree/image-2.png";
// import Image3 from "../../assests/all-images/Ebony-tree/image-3.png";
// import Image4 from "../../assests/all-images/Ebony-tree/image-4.png";
// import Image5 from "../../assests/all-images/Ebony-tree/image-5.png";
// import Image6 from "../../assests/all-images/Ebony-tree/image-6.png";
// import Image7 from "../../assests/all-images/Ebony-tree/image-8.png";
// import Image8 from "../../assests/all-images/Ebony-tree/image-9.png";
import "./karungali.css";
const EbonyTree = () => {
  return (
    <>
      <Container className="mt-0 karungali" style={{ padding: "120px 0px" }}>
        <div>
          <h4 className="text-center header">Karungali Wood Benefits (Ebony Tree)</h4>
        </div>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image1}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image2}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image3}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image4}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image5}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image6}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image7}/>
          </Col>
        </Row>
        <Row className="mt-4 px-3">
          <Col xs={12} className="text-center">
          <img src={Image8}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EbonyTree;
