
import React from 'react';
import './card.css';

const Card = ({ image, name, price, shortDescription, productId,title }) => {

    const WhatsApp = () => {
        const protocol = window.location.protocol; // e.g., "http:" or "https:"
        const hostname = window.location.hostname; // e.g., "srisakthi.com"
        const port = window.location.port ? `:${window.location.port}` : ""; // Include port only if it exists
        const baseUrl = `${protocol}//${hostname}${port}`; // Full base URL with protocol and port
        const whatsappMessage = 
            `*Hi, I’m interested in the following products :* %0A` +
            `*Product Header : ${title}* %0A` +
            `*Product Name : ${name}* %0A` +
            `*productId : ${productId}* %0A` +
            `*product Image : ${baseUrl}${image}* %0A` +
            `Could you please share more details about these items, including pricing and availability? Looking forward to your response.`;

        const whatsappUrl = `https://wa.me/7397259981?text=${whatsappMessage}`;
        window.open(whatsappUrl, "_blank");
    };

    return (
        <div className="product-item text-center animate__animated animate__fadeIn">
            <img src={image} alt={name} className="card-img" />
            <div className='mt-1'>
                <h2 className="h5">{name}</h2>
                <p className="price">{price}</p>
                <p className="short-description">{shortDescription}</p>
                {/* <a href={WhatsApp} className="btn btn-primary">
                    Enquiry
                </a> */}
                <button onClick={WhatsApp} className="btn btn-primary w-50">
                    Call Enquiry
                </button>
            </div>

        </div>
    );
};

export default Card;
